import React from "react"

import { Link } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Image from "../components/image"

const IndexPage = () => (
  <Layout activePage="portfolio">
    <SEO title="Startseite" />
    <h1>Herzlich willkommen auf der Webseite von Frank Kürzel!</h1>

    <div className="card-columns pt-3">
      <div className="card">
        <Link to="/portraits">
          <Image imgName="Frank-Kuerzel-Portrait_010.jpg" />
        </Link>
        <div className="card-body">
          <h2 className="card-title">
            <Link to="/portraits">Portraits</Link>
          </h2>
        </div>
      </div>

      <div className="card">
        <Link to="/lifestyle">
          <Image imgName="Frank-Kuerzel-Lifestyle_003.jpg" />
        </Link>
        <div className="card-body">
          <h2 className="card-title">
            <Link to="/lifestyle">Lifestyle</Link>
          </h2>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          grösse 190 - hight 190
          <br />
          konfektion 98/102 - size 98/102
          <br />
          oberweite 108 - brust 108
          <br />
          taille 96 - waist 96
          <br />
          hüfte 99 - hips 99
          <br />
          schuhe 44/45 - shoes 44/45
          <br />
          augen grün - eyes green
          <br />
          haare braun - hair brown
          <br />
          special - special
          <br />
          bademoden - bathing suits
          <br />
          wäsche - underwear
          <br />
          hände - hands
        </div>
      </div>

      <div className="card">
        <Link to="/advertising">
          <Image imgName="Frank-Kuerzel-Advertising_009.jpg" />
        </Link>
        <div className="card-body">
          <h2 className="card-title">
            <Link to="/advertising">Advertising</Link>
          </h2>
        </div>
      </div>

      <div className="card">
        <Link to="/business">
          <Image imgName="Frank-Kuerzel-Business_003.jpg" />
        </Link>
        <div className="card-body">
          <h2 className="card-title">
            <Link to="/business">Business</Link>
          </h2>
        </div>
      </div>

      <div className="card text-white bg-secondary">
        <div className="card-body">
          <h2 className="card-title">Kontakt</h2>
          <p>
            Frank Kürzel
            <br />
            frank@kuerzel.net
            <br />
            +49 177 2571000
          </p>
        </div>
      </div>

      <div className="card">
        <Link to="/sports-body">
          <Image imgName="Frank-Kuerzel-Sport-Body_008.jpg" />
        </Link>
        <div className="card-body">
          <h2 className="card-title">
            <Link to="/sports-body">Sports &amp; Body</Link>
          </h2>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
